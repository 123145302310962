import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_next_select = _resolveComponent("vue-next-select")!

  return (_openBlock(), _createBlock(_component_vue_next_select, {
    searchable: true,
    "close-on-select": "",
    options: _ctx.clients.clients,
    "value-by": "id",
    "label-by": "name",
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue')))
  }, null, 8, ["options", "model-value"]))
}