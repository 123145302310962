
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import SelectClient from "@/components/SelectClient.vue";
import ApiService from "@/services/api.service";
import { Paging, totalPages } from "@/utils/Pagination";
import { AxiosResponse } from "axios";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { Client, ClientResponse } from "../Client.vue";
import VueNextSelect from 'vue-next-select'
import 'vue-next-select/dist/index.min.css'

export interface TopClient {
  name: string
  value: string
}

export interface Quotation {
  id: number
  user_id: number
  date: string
  Ref: string
  client_id: number
  warehouse_id: number
  tax_rate: number
  TaxNet: number
  discount: number
  shipping: number
  GrandTotal: number
  statut: string
  notes: any
  created_at: string
  updated_at: string
  deleted_at: string | null
  client: Client
}
export interface ClientQuotation {
  quotations: Quotation[],
  totalRows: number
}
export default defineComponent({
  name: 'Quotation',
  components: { SelectClient, VueNextSelect },
  setup() {
    const selectedClient = ref('')
    const clients = ref<ClientResponse>({
      clients: [],
      totalRows: 0
    })
    const quotations = ref<ClientQuotation>({
      quotations: [],
      totalRows: 0
    })
    const paginate = reactive<Paging>({
      page: 0,
      SortField: 'Name',
      SortType: 'desc',
      search: '',
      limit: 10
    })
    const currentPage = ref(1)
    const getClients = async () => {
      try {
        const { data }: AxiosResponse<ClientResponse> = await ApiService.get('/clients/clients', {
          params: {
            limit: 100,
            search: '',
            SortType: 'asc',
            SortField: 'name',
            page: 1
          }
        })
        clients.value = data
      } catch (error) {
        console.log(error);
      }
    }
    const getQuotations = async () => {
      try {
        const { data }: AxiosResponse<ClientQuotation> = await ApiService.get('/report/client_quotations?limit=10&id=1', {
          params: {
            limit: 100,
            id: selectedClient.value
          }
        })
        console.log(data);
        
        quotations.value = data
      } catch (error) {
        console.log(error);
         
      }
    }
    onMounted(() => {
      getClients()
    })

    return {
      currentPage,
      paginate,
      selectedClient,
      getQuotations,
      quotations,
      clients,
    }
  }
})
