
/* eslint-disable no-unused-vars */
import 'vue-next-select/dist/index.min.css'
import VueNextSelect from 'vue-next-select'
import ApiService from "@/services/api.service";
import { defineComponent, onMounted, ref } from "vue";
import { AxiosResponse } from 'axios'
import { ClientResponse } from "@/views/reports/Client.vue";
interface SelectFocusEvent {
    target: HTMLSelectElement
}

export default defineComponent({
  name: 'SelectClient',
  components: {
    VueNextSelect
  },
  props: {
    modelValue: {
      required: true,
      type: [Number, String]
    }
  },
  emits: ['update:modelValue'],
  setup(){
    const load = ref()
    const observer = ref<IntersectionObserver | null>(null)
    const clients = ref<ClientResponse>({
      clients: [],
      totalRows: 0
    })
    const currentPage = ref(1)
    const getClients = async () => {
      try {
        const { data }: AxiosResponse<ClientResponse> = await ApiService.get('/clients/clients', {
          params: {
            limit: 6,
            search: '',
            SortType: 'asc',
            SortField: 'name',
            page: currentPage.value
          }
        })
        clients.value.clients = [...clients.value.clients, ...data.clients]
      } catch (error) {
        console.log(error);
      }
    }
    async function onOpen ($event: SelectFocusEvent) {
      // if (hasNextPage) {
      // observer.value?.observe(load.value)
      // }
    }
    function onClose ($event: SelectFocusEvent) {

      observer.value?.disconnect();
    }
    async function infiniteScroll ([{isIntersecting, target}]: IntersectionObserverEntry[]) {
      if (isIntersecting) {
        const elTarget = target as HTMLInputElement
        const ul = elTarget.offsetParent;
        currentPage.value = currentPage.value + 1;
        await getClients()
        if (ul && elTarget.offsetParent) {
          ul.scrollTop = elTarget.offsetParent.scrollTop
        }
      }
    }
    onMounted(async () => {
      await getClients()
      observer.value = new IntersectionObserver(infiniteScroll);
    })
    return {
      onOpen,
      onClose,
      clients
    }
  }
})
